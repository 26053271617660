.swimlanes-directions {
	margin: 1.5rem auto 0 auto;
	padding: 0.75rem 1rem 1rem 1rem;
	border: 2px solid rgb(254 240 138); /* Tailwind's yellow-200 */
	border-radius: 3px;
	font-size: 0.875rem; /* Tailwind's text-sm */
	line-height: 1.25rem;
	background-color: rgb(254 252 232); /* Tailwind's yellow-50 */
	max-width: 81rem; /* For larger screens */
	width: 90%;
}

.swimlanes-directions > h2 {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.swimlanes-directions > ol > li {
	margin: 0.25rem 0 0.25rem 0;
}

.swimlanes-directions .example-add-button {
	font-size: 0.875rem;
	line-height: 1.25rem;
	background-color: #4caf50;
	color: white;
	border-radius: 0.25rem;
	text-align: center;
	width: 1.25rem;
}
