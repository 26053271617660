.labeled-tag {
	font-size: 0.8em;
	color: #4d9538;
	padding: 2px 5px;
	border-radius: 3px;
	margin-right: 5px;
}

.labeled-text {
	font-size: 1.2em;
	padding: 2px 5px;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
