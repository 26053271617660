.legalBody {
	font-family: Arial, sans-serif;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	line-height: 1.6;
}
h1 {
	color: #333;
	font-weight: bolder;
	margin-bottom: 20px;
}

ol {
	counter-reset: section;
	list-style-type: none;
	padding-left: 0;
}
ol > li {
	margin-bottom: 20px;
}
ol > li:before {
	counter-increment: section;
	content: counter(section) ". ";
	font-weight: bold;
}
.section-title {
	font-weight: bold;
	display: inline;
}
.sub-list {
	counter-reset: subsection;
	list-style-type: none;
	margin-left: 20px;
	margin-top: 10px;
}
.sub-list > li {
	margin-top: 5px;
}
.sub-list > li:before {
	counter-increment: subsection;
	content: counter(section) "." counter(subsection) ". ";
	font-weight: normal;
}
.alpha-list {
	list-style-type: lower-alpha;
	margin-left: 40px;
	margin-top: 10px;
}
