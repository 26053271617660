.tab-container {
	padding-top: 53px;
}

.tabs {
	list-style: none;
	list-style-image: none;
}

.tab {
	float: left;
	margin-left: 0.15em;
	margin-right: 0.15em;
	text-transform: capitalize;
	background-color: #fff;
	border: 1px solid #ccc;
	color: #999;

	border-bottom-width: 3px;

	border-top-right-radius: 15px;
	transition: background-color 0.2s linear;
}

.tab span {
	cursor: pointer;
	display: block;
	padding: 0.5em 1em;
	font-weight: bold;
	text-decoration: none;
}

.tab:hover {
	background-color: #f2f2f2;
	border-bottom-color: #ccc;
}

.tab:first-child {
	margin-left: 0.75em;
}

.tab-current {
	border-bottom-color: #fff;
	color: #4d9538;
}

.tab-current:hover {
	background-color: #fff;
	border-bottom-color: #fff;
}
