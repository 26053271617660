body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.swimlanes-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.swimlane {
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: whitesmoke;
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	position: relative;
	box-sizing: border-box;
}

.colheader {
	display: flex;
	justify-content: space-between;
	margin: 8px;
	height: 50px;
	column-gap: 5px;
	user-select: none;
}

.colheader h3 {
	margin: 0;
}

.colheader-container {
	display: flex;
	justify-content: space-between;
	column-gap: 5px;
}

.cardcount {
	color: white;
	background: #333;
	border-radius: 5px;
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 30px;
}

.add-job-button {
	color: white;
	background-color: #4caf50;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	height: 30px;
	width: 30px;
	padding: 0;
}

.add-job-button > span.material-symbols-outlined {
	line-height: 30px;
}

.add-job-button:disabled {
	opacity: 50%;
	cursor: not-allowed;
}
.add-job-button:hover:not(:disabled) {
	background-color: #46a049;
}

.add-job-button:active:not(:disabled) {
	background-color: green;
}

.swimlane-droparea {
	background: whitesmoke;
	padding: 0.5rem;
	min-height: 250px;
	border-radius: 5px;
	flex: 1 1 0%;
}

.swimlane-droparea-draggingover {
	background: #d7ebff;
}

.swimlane-done {
	height: 13rem;
	border: 1px solid transparent;
	flex: 1 1 0%;
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
}

.swimlane-done-droparea {
	padding: 0.5rem;
	height: 100%;
}

.swimlane-done-icon {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px dashed #d1d5db;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swimlane-done-icon .material-symbols-outlined {
	font-size: 3.75rem;
	line-height: 1;
	color: #d1d5db;
	user-select: none;
}

.swimlane-done-draggingover .swimlane-done-icon {
	background-color: #e0f2fe; /* Tailwind's sky-100 */
	border-color: #38bdf8; /* Tailwind's sky-400 */
}

.swimlane-done-draggingover .material-symbols-outlined {
	color: #38bdf8; /* Tailwind's sky-400 */
}

.card {
	user-select: none;
	padding: 1rem;
	margin-bottom: 0.5rem;
	border: 1px solid #ddd;
	border-radius: 3px;
	background: white;
}

.card-company {
	font-weight: 700;
}

.card-title {
	padding-left: 0.25rem;
	color: rgb(107 114 128); /* Tailwind's gray-500 */
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.card-dragging {
	background: lightgreen;
}

/* "div" is used in addition to the class name here in order to make the selector more specific so it overrides the context menu's default style */
div.job-card-context-menu-item {
	display: flex;
	column-gap: 0.667rem;
}

/* TODO: Submenu class is currently unused, but may be needed in the future */
div.job-card-context-menu-item,
div.job-card-context-menu-submenu > div:first-child {
	font-size: 1.05rem;
}

div.job-card-context-menu-item > span.material-symbols-outlined {
	font-variation-settings: "FILL" 0;
	font-size: 1.5rem;
}

div.job-card-context-menu-item-loading {
	background: linear-gradient(to right, #f8f8f8 33%, #c3c3c3 50%, #f8f8f8 66%);
	background-size: 300%;
	animation: gradient-anim 1.5s linear infinite;
	font-style: italic;
}

@keyframes gradient-anim {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: -200%;
	}
}

@tailwind base;
@tailwind components;
@tailwind utilities;
