.addJobForm {
	margin-top: 20px;
}

.add-job-textbox-form-row textarea {
	height: 200px;
}

.add-job-form-row + .add-job-form-row {
	margin-top: 10px;
}

.add-job-form-row input {
	margin-top: 3px;
}

/*Add Job Button Lower right of Modal*/
.add-job-form-row button {
	position: relative;
	float: right;
	background-color: #4d9538;
	color: white;
	border: none;
	padding: 12px 24px;
	font-size: 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.add-job-form-row button:hover:not(:disabled) {
	background-color: #3a7229;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.add-job-form-row button:active:not(:disabled) {
	transform: translateY(2px) translateX(1px);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.add-job-form-row button:disabled {
	color: transparent;
	background-color: #aedca3;
}

.add-job-form-row-loadingwheel {
	width: 1.75rem;
	color: white;
	fill: #22d3ee;
}

/* Floating Label Styles */
.add-job-form-row {
	position: relative;
	margin-bottom: 20px;
}

.add-job-form-row input,
.add-job-form-row textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
}

.add-job-form-row label {
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	font-size: 16px;
	color: #999;
	pointer-events: none;
	transition: all 0.3s ease;
}

.add-job-form-row input:focus,
.add-job-form-row textarea:focus {
	border-color: #4d9538;
	outline: none;
}

.add-job-form-row input:focus + label,
.add-job-form-row input:not(:placeholder-shown) + label,
.add-job-form-row textarea:focus + label,
.add-job-form-row textarea:not(:placeholder-shown) + label {
	top: 0;
	font-size: 12px;
	color: #4d9538;
	background-color: white;
	padding: 0 5px;
	transform: translateY(-50%);
}

/* Adjustments for textarea */
.add-job-textbox-form-row {
	position: relative;
	margin-bottom: 20px;
}

.add-job-form-row textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
	min-height: 6rem;
	max-height: 24rem;
}

.add-job-textbox-form-row label {
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 16px;
	color: #999;
	pointer-events: none;
	transition: all 0.3s ease;
}

.add-job-textbox-form-row textarea:focus,
.add-job-textbox-form-row textarea:not(:placeholder-shown) {
	border-color: #4d9538;
	outline: none;
}

.add-job-textbox-form-row textarea:focus + label,
.add-job-textbox-form-row textarea:not(:placeholder-shown) + label {
	top: -8px;
	font-size: 12px;
	color: #4d9538;
	background-color: white;
	padding: 0 5px;
}
