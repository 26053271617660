.free-trial-button {
	background-color: rgb(34 197 94); /* Tailwind bg-green-500 */
	border-radius: 0.25rem; /* Tailwind rounded, 4px */
	color: rgb(255 255 255); /* Tailwind text-white */
	font-size: 1.875rem; /* Tailwind text-3xl, 30px */
	font-weight: 600; /* Tailwind font-semibold */
	line-height: 2.25rem; /* Tailwind text-3xl, 36px */
	padding: 1.25rem; /* Tailwind p-5, 20px */
}

@keyframes glint {
	0% {
		transform: translateX(-200%);
	}
	100% {
		transform: translateX(500%);
	}
}

.glint-link {
	position: relative;
	overflow: hidden;
	box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
}

.glint-link::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 25%;
	height: 100%;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.55) 50%,
		rgba(255, 255, 255, 0) 100%
	);

	animation: glint 2s infinite linear;
	transform: translateX(-100%);
}

.glint-link:active {
	transform: translate(2px, 2px);
	box-shadow: 0px 0px;
}

.feature-free-trial-button {
	background-color: rgb(255 255 255); /* bg-white */
	border-radius: 0.5rem; /* rounded-lg; 8px */
	display: inline-block; /* inline-block */
	font-weight: 600; /* font-semibold */
	padding-bottom: 0.75rem; /* py-3; 12px */
	padding-left: 2rem; /* px-8; 32px */
	padding-right: 2rem; /* px-8; 32px */
	padding-top: 0.75rem; /* py-3; 12px */
	transition-duration: 150ms; /* transition-colors */
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke; /* transition-colors */
	transition-timing-function: cubic-bezier(
		0.4,
		0,
		0.2,
		1
	); /* transition-colors */
}

/* Button on the tracking feature page */

.tracking-free-trial-button {
	color: rgb(147 51 234); /* text-purple-600 */
}

.tracking-free-trial-button:hover {
	background-color: rgb(250 245 255); /* hover:bg-purple-50 */
}

/* Button on the cover letter feature page */

.cover-letter-free-trial-button {
	color: rgb(37 99 235); /* text-blue-600 */
}

.cover-letter-free-trial-button:hover {
	background-color: rgb(239 246 255); /* hover:bg-blue-50 */
}

/* Button on the resume feature page */

.resume-free-trial-button {
	color: rgb(22 163 74); /* text-green-600 */
}

.resume-free-trial-button:hover {
	background-color: rgb(240 253 244); /* hover:bg-green-50 */
}

/* Button on the customization feature page */

.customization-free-trial-button {
	color: rgb(217 119 6); /* text-amber-600 */
}

.customization-free-trial-button:hover {
	background-color: rgb(255 251 235); /* hover:bg-amber-50 */
}
