/**************Layout for the paperclip and Drop Zone*****************/
.resumeFileInputHdr {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: #fff; /* Adjust as needed */
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}

.resumeFileInputHdr-l {
	display: flex;
	align-items: center;
}

.resumeFileInputHdr-r {
	flex-grow: 1;
	align-items: center;
	margin-left: 20px; /* Adds some space between the button and the dropzone */
}

/**************PaperClip Formatting******************/
.resumeFileInputBtn {
	display: block;
	height: 40px;
	width: 40px;
	background-color: #26a69a;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 1.25rem;
	justify-content: center;
	align-items: center;
}

.resumeFileInputBtn:hover {
	background-color: #219186;
}

.resumeFileInputBtn:active {
	background-color: #1c7d73;
}
/***************Dashed DropZone *********************/
.dropzone-base {
	border: 2px dashed;
	padding: 25px;
	border-radius: 5px;
	text-align: center;
	user-select: none;
	cursor: not-allowed;
}

.dropzone-idle {
	border-color: #bbb;
	color: #bbb;
}

.dropzone-onDrag {
	background-color: #e0f2fe; /* Tailwind's sky-100 */
	border-color: #38bdf8; /* Tailwind's sky-400 */
	color: #38bdf8; /* Tailwind's sky-400 */
}
