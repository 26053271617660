/* ContactFormModal.css */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	background-color: white;
	padding: 2rem;
	border-radius: 8px;
	width: 100%;
	max-width: 400px;
}

.close-modal-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
}

.open-modal-btn {
	background-color: #007bff;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.submit-btn {
	float: right;
	background-color: #4d9538;
	color: white;
	border: none;
	padding: 12px 24px;
	font-size: 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.submit-btn button:hover {
	background-color: #3a7229;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-btn:active {
	transform: translateY(2px) translateX(1px);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.modal-content input,
.modal-content textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 1rem;
	border: 1px solid #ccc;
	border-radius: 5px;
}
.modal-content textarea {
	height: 110px;
}

.material-button {
	margin-top: 6px;
	background-color: transparent;
	border: none;
	color: #0a0d09;
	cursor: pointer;
	transition: color 0.3s ease;
}

.material-button span {
	font-size: 50px;
}

.material-button:hover {
	color: #3a7229;
}

.contact-form-video-text {
	font-weight: 900;
	margin-bottom: 1rem;
	color: rgb(51, 51, 51);
}

.contact-form-video-text > a {
	color: rgb(2 132 199); /* Tailwind's text-sky-600 */
	text-decoration: underline;
}
