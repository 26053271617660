.modal {
	border: 3px solid gray;
	border-radius: 5px;
	padding: 20px;
	inset-block-start: 0px;
	inset-block-end: 0px;
}

.modal-close-button {
	position: absolute;
	z-index: 1000;
	top: 8px;
	right: 8px;
	padding: 0;
	/* width: 30px;
    height: 30px; */
	border-radius: 5px;
	background-color: white;
	border: 1px solid #ccc;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-out;
	/* box-shadow: 0 1px 3px rgba(0,0,0,0.1); */
	user-select: none;
}

.modal-close-button::before {
	/* content: "\2715"; Unicode for 'x' */
	color: #888;
	font-size: 16px;
	font-weight: bold;
}

.modal-close-button:hover {
	background-color: #ff4136; /* A bold red color */
	border-color: #ff4136;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.modal-close-button:hover::before {
	color: white;
	font-size: 18px;
}
