.detailmodal {
	height: calc(42vh + 20.875rem);
	aspect-ratio: 1.4545;
}

.detailmodal-innercontainer {
	display: flex;
	flex-direction: column;
	/* overflow:hidden; */
	height: 100%;
}

.detailmodal-jobid {
	grid-area: detailmodal-jobid;
}

.detailmodal-companytitle {
	grid-area: detailmodal-companytitle;
}

.detailmodal-jobtitle {
	grid-area: detailmodal-jobtitle;
}

.detailmodal-contact {
	display: none;
	grid-area: detailmodal-contact;
	border: 1px solid black;
}

.detailmodal-nextsteps {
	display: none;
	grid-area: detailmodal-nextsteps;
	border: 1px solid black;
}

.detailmodal-row {
	/* grid-area: detailmodal-editor;	 */
	flex: content;
	display: flex;
	flex-direction: row;
	overflow-y: auto;
	min-height: 0; /* Prevents overflow; See https://stackoverflow.com/a/66689926 */
}

/* Wrapper around MDX Editor and Loading Wheel */
.detailmodal-editor {
	position: relative; /* Positions the Loading Wheel inside this wrapper */
	width: 100%; /* Makes MDX Editor fill available width space */
	height: 100%;
}

/* MDX Editor component */
.detailmodal-mdxeditor {
	height: 100%; /* Sets a height on MDX Editor to fill empty space and so content editable area does not overflow */
	display: flex; /* Makes MDX Editor a flexbox to stop children overflowing */
	flex-direction: column;
	z-index: 30; /* DO NOT REMOVE. Ensures that tooltips, drop-down lists, menus, and other visual elements created by MDX Editor are not hidden behind the modal window. */
}

/* MDX Editor toolbar */
.detailmodal-mdxeditor > div:first-child {
	flex: 0 0 auto; /* Prevents toolbar from shrinking */
}

/* Wrapper around editable content area in MDX Editor */
div:has(> .jobdetails-resume-scroll) {
	min-height: 0; /* Prevents overflow; See https://stackoverflow.com/a/66689926 */
}

.detailmodal-history {
	grid-area: detailmodal-history;
	min-width: 20%;
	display: none;
	overflow: auto;
}

.detailmodal-tabs {
	grid-area: detailmodal-tabs;
}

/* Editable content area in MDX Editor */
.jobdetails-resume-scroll {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 0;
	height: 100%;
}

.jobdetails-resume-disabled {
	cursor: not-allowed;
}
