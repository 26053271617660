.feature-container {
	display: flex;
	align-items: center;
	gap: 2rem;
	max-width: 1200px;
	margin: 2rem auto;
	padding: 1rem;
}

.left-handed {
	flex-direction: row;
}

.right-handed {
	flex-direction: row-reverse;
}

.image-container,
.content-container {
	flex: 1;
}

.image-container {
	/* Add padding to accommodate the shadow */
	padding: 10px;
}

.feature-image {
	max-width: 100%;
	height: auto;
	border-radius: 20px; /* Rounded corners */
	box-shadow: 10px 10px 5px rgba(144, 238, 144, 0.4); /* Light green shadow */
}

.feature-title {
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

.feature-description {
	font-size: 1rem;
	line-height: 1.5;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
	.feature-container {
		flex-direction: column;
		gap: 1rem;
	}

	/* Override the left-handed and right-handed classes on mobile */
	.left-handed,
	.right-handed {
		flex-direction: column;
	}

	.image-container,
	.content-container {
		width: 100%;
	}

	/* Adjust shadow for mobile view */
	.feature-image {
		box-shadow: 0 5px 5px rgba(144, 238, 144, 0.4);
	}
}
