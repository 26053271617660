/* JobSearchHeader.css */

.job-search-header {
	background-color: #f0fdf4; /* Light green background */
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}

.job-search-label {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	font-weight: bold;
	font-size: 36px;
}
.job-search-pricing {
	padding-bottom: 3rem;
}

.job-search-feature {
	background-color: #f0fdf4; /* Light green background */
}

.header-content {
	max-width: 42rem;
	width: 100%;
	text-align: center;
}

.header-title {
	font-size: 2.25rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.header-content-mappy {
	font-size: 1.75rem;
	font-weight: bold;
}

.text-navy {
	color: #1e293b;
}

.text-green {
	color: #16a34a;
}

.header-subtitle {
	color: #4b5563;
	margin-bottom: 2rem;
}

.header-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.email-input {
	width: 100%;
	padding: 0.75rem;
	border-radius: 0.375rem;
	border: 1px solid #d1d5db;
}

.email-input:focus {
	outline: none;
	border: 2px solid #22c55e;
}

.submit-button {
	width: 100%;
	background-color: #16a34a;
	color: white;
	padding: 0.75rem;
	border-radius: 0.375rem;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.submit-button:hover {
	background-color: #15803d;
}

.toast-happy {
	background: #16a34a;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	width: 34vw;
	padding: 30px 20px;
}

.toast-unhappy {
	background: #a31616;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	width: 34vw;
	padding: 30px 20px;
}

.content-container a {
	display: inline-block;
	color: #2563eb;
	text-decoration: none;
	font-size: 0.875rem;
	font-weight: 500;
	padding-bottom: 2px;
	border-bottom: 1px solid transparent;
	transition: all 0.2s ease-in-out;
	margin-top: 1rem;
}

.content-container a:hover {
	transform: translateX(4px);
}

.content-container a::after {
	content: "→";
	display: inline-block;
	margin-left: 4px;
	transition: transform 0.2s ease-in-out;
}

.content-container a:hover::after {
	transform: translateX(2px);
}
