/*NAVBAR BUTTON*/
.profile-badge-container {
	display: flex;
	flex-direction: row-reverse;
}

.profile-badge {
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background-color: #eee;
	padding: 8px 16px;
}

.profile-badge-picture {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 8px;
}

.profile-badge-name {
	font-weight: bold;
}

/*Modal*/
.profilemodal {
	height: 90%;
	width: 50%;
	padding: 0;
}

.profilemodal-section-resume {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

.mdxEditorContainer {
	overflow-y: auto;
	overflow-x: hidden;
}

/* MDX Editor component */
.profilemodal-mdxeditor {
	display: flex; /* Makes MDX Editor a flexbox to stop children overflowing */
	flex-direction: column;
	height: 100%; /* Set a height on MDX Editor and so content editable area does not overflow */
}

/* MDX Editor toolbar */
.profilemodal-mdxeditor > div:first-child {
	flex: 0 0 auto; /* Prevents toolbar from shrinking */
}

/* Wrapper around editable content area in MDX Editor */
div:has(> .profilemodal-resume-scroll) {
	min-height: 0; /* Prevents overflow; See https://stackoverflow.com/a/66689926 */
}

/* Editable content area in MDX Editor */
.profilemodal-resume-scroll {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 0;
	height: 100%; /* Set height to prevent overflow */
}

/*Personal Info - Hidden*/
.profilemodal-section-info {
	border-bottom: 1px solid #eee;
}

.profilemodal-email,
.profilemodal-phone {
	display: flex;
	align-items: center;
	column-gap: 5px;
}

/*KSA AREA -- This has been disabled*/
.profilemodal-section-ksa h3 {
	margin-top: 0;
}

.profilemodal-ksa-columns-container {
	display: flex;
}

.profilemodal-ksa-column {
	flex-grow: 1;
}

.profilemodal-ksa-item {
	margin-bottom: 5px;
	width: fit-content;
	padding: 5px;
	background-color: #eee;
	border-radius: 5px;
	font-size: 14px;
}
